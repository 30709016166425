import { Directive, ElementRef, Input, OnDestroy, OnInit } from '@angular/core';

let observer: ResizeObserver;

export interface BreakpointConfig {
  [point: string]: number | string | BreakPoint;
}

export interface PointValue {
  value: number;
  unit: string;
}
export interface BreakPoint {
  minWidth?: number | string;
  maxWidth?: number | string;
  minHeight?: number | string;
  maxHeight?: number | string;
  minRatio?: number;
  maxRatio?: number;
  operator?: 'and' | 'or';
}

const enum BreakPointType {
  SET_AND_REACHED = 1,
  SET_AND_NOT_REACHED = 2,
  NOT_SET = 3,
}

const breakPointConfig: BreakpointConfig[] = [];
// Default breakpoints that should apply to all observed
// elements that don't define their own custom breakpoints.
const defaultBreakpoints: BreakpointConfig = { SM: 384, MD: 576, LG: 768, XL: 960 };

// Only run if ResizeObserver is supported.
if ('ResizeObserver' in self) {
  // Create a single ResizeObserver instance to handle all
  // container elements. The instance is created with a callback,
  // which is invoked as soon as an element is observed as well
  // as any time that element's size changes.
  observer = new ResizeObserver((entries: any) => {
    // We wrap it in requestAnimationFrame to avoid this error - ResizeObserver loop limit exceeded
    window.requestAnimationFrame(() =>
      entries.forEach((entry: any) => {
        // If breakpoints are defined on the observed element,
        // use them. Otherwise use the defaults.
        const breakpoints = breakPointConfig[+entry.target.dataset.breakpoint];
        if (!breakpoints) throw new Error('No breakpoints defined for this element, but observer still initialized!');

        // Update the matching breakpoints on the observed element.
        Object.keys(breakpoints).forEach((breakpoint) => {
          const point = breakpoints[breakpoint];
          if (typeof point === 'string' || typeof point === 'number') {
            toggleClass(entry.target, breakpoint, sizeGreaterThan(entry.target, entry.contentRect.width, point));
          } else if (typeof point === 'object') {
            // This is a more complex breakpoint.
            const currentState = {
              minHeight:
                'minHeight' in point && point.minHeight != null
                  ? sizeGreaterThan(entry.target, entry.contentRect.height, point.minHeight) === true
                    ? BreakPointType.SET_AND_REACHED
                    : BreakPointType.SET_AND_NOT_REACHED
                  : BreakPointType.NOT_SET,
              minWidth:
                'minWidth' in point && point.minWidth != null
                  ? sizeGreaterThan(entry.target, entry.contentRect.width, point.minWidth) === true
                    ? BreakPointType.SET_AND_REACHED
                    : BreakPointType.SET_AND_NOT_REACHED
                  : BreakPointType.NOT_SET,
              maxHeight:
                'maxHeight' in point && point.maxHeight != null
                  ? sizeLessThan(entry.target, entry.contentRect.height, point.maxHeight) === true
                    ? BreakPointType.SET_AND_REACHED
                    : BreakPointType.SET_AND_NOT_REACHED
                  : BreakPointType.NOT_SET,
              maxWidth:
                'maxWidth' in point && point.maxWidth != null
                  ? sizeLessThan(entry.target, entry.contentRect.width, point.maxWidth) === true
                    ? BreakPointType.SET_AND_REACHED
                    : BreakPointType.SET_AND_NOT_REACHED
                  : BreakPointType.NOT_SET,
              minRatio:
                'minRatio' in point && point.minRatio != null
                  ? getRatio(entry.target, entry.contentRect) >= point.minRatio
                    ? BreakPointType.SET_AND_REACHED
                    : BreakPointType.SET_AND_NOT_REACHED
                  : BreakPointType.NOT_SET,
              maxRatio:
                'maxRatio' in point && point.maxRatio != null
                  ? getRatio(entry.target, entry.contentRect) < point.maxRatio
                    ? BreakPointType.SET_AND_REACHED
                    : BreakPointType.SET_AND_NOT_REACHED
                  : BreakPointType.NOT_SET,
            };

            // Toggle the class based on the breakpoint type.
            const config = Object.entries(currentState)
              // Only include properties which are set
              .filter(([key, value]) => value !== BreakPointType.NOT_SET);
            const shouldSet =
              point.operator === 'or'
                ? // Some set properties in the breakpoint must be reached in order for the class to be added.
                  config.some(([key, value]) => value === BreakPointType.SET_AND_REACHED)
                : // All set properties in the breakpoint must be reached in order for the class to be added.
                  config.every(([key, value]) => value === BreakPointType.SET_AND_REACHED);
            toggleClass(entry.target, breakpoint, shouldSet);
          }
        });
      }),
    );
  });
}

function sizeGreaterThan(element: HTMLElement, elmSize: number, point: string | number) {
  const pointValues = getUnitValue(point);
  const minSize = convertToPixel(element, pointValues);
  return elmSize >= minSize;
}
function sizeLessThan(element: HTMLElement, elmSize: number, point: string | number) {
  const pointValues = getUnitValue(point);
  const maxSize = convertToPixel(element, pointValues);
  return elmSize < maxSize;
}
function getRatio(element: HTMLElement, contentRect: any) {
  const { width, height } = contentRect;
  return width / height;
}
function toggleClass(element: HTMLElement, className: string, flag: boolean) {
  const hasClass = element.classList.contains(className);
  if (!flag && !hasClass) return;

  return flag ? !hasClass && element.classList.add(className) : element.classList.remove(className);
}
function convertToPixel(element: HTMLElement, pointValue: PointValue) {
  // prettier-ignore
  switch (pointValue.unit) {
    case 'em':  return pointValue.value * (getUnitValue(getComputedStyle(element).fontSize).value || 1);
    case 'rem': return pointValue.value * (getUnitValue(getComputedStyle(document.body).fontSize).value || 1);
    case 'px':
    default:    return pointValue.value;
  }
}
function getUnitValue(point: string | number): PointValue {
  // eslint-disable-next-line no-useless-escape
  const pointValues = /([\d\.]+)(em|rem|px)*/.exec(`${point}`) || [];
  return { value: +pointValues[1], unit: pointValues[2] || 'px' };
}

@Directive({ selector: '[libContainerResize]', standalone: true })
export class ContainerResizeDirective implements OnInit, OnDestroy {
  @Input() libContainerResize: BreakpointConfig = defaultBreakpoints; // Breakpoints to set

  constructor(private el: ElementRef) {}

  ngOnInit() {
    if (!observer || !this.el) {
      return;
    }
    if (this.libContainerResize != null) {
      const index = breakPointConfig.push(this.libContainerResize);
      this.el.nativeElement.setAttribute('data-breakpoint', `${index - 1}`);
      observer.observe(this.el.nativeElement);
    }
  }

  ngOnDestroy() {
    observer?.unobserve(this.el.nativeElement);
  }
}
